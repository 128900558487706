import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

export interface Credentials {
  username: string;
  password: string;
}

interface AuthTokens {
  accessToken: string;
  refreshToken: string;
  expiresInSeconds: number;
}

const PILEJE_ADMIN_USERNAMES = [
  's.david@pileje.com', // Séverine DAVID
  'b.lechat@pileje.com', // Blandine LECHAT
  'i.lebreton@pileje.com', // Isabelle LEBRETON
  'e.tuffreau@pileje.com', // Elodie TUFFREAU
  's.hardouin@pileje.com', // Sandy HARDOUIN
  'g.baumard@pileje.com', // Gaëlle BAUMARD
  'l.rethore@pileje.com', // Laëtitia RETHORE
  'interimsafd2@pileje.com', // Intérim 2
  'c.cardona@pileje.com', // Charline CARDONA
  'z.hadji@pileje.com', // Zinebe HADJI
  'a.pelloquin@pileje.com', // Auxane PELLOQUIN
  'a.berlin@pileje.com', // Aurélie BERLIN
  'b.huard@pileje.com', // Benjamin HUARD
  'a.dodier@pileje.com', // Alexandre DODIER
  't.decoha@pileje.com', // Théo DECOHA
  'myang+testdemo1@numahealth.com', // Maël test
  'myang+test2412082@numahealth.com', // Maël testpreprod

];

export const IP_ADDRESS_WHITELIST = [
  // PILEJE
  '165.85.223.57',
  '165.85.223.5',
  '208.127.181.137',
  '208.127.181.138',
  '130.41.157.117',
  '130.41.157.202',
  '165.85.255.128',
  '128.77.84.147',
  '165.85.236.63',
  '165.85.236.64',
  '128.77.56.225',
  '90.63.228.70',
  '165.85.168.239',
  '165.85.168.240',
  '130.41.123.101',
  '130.41.123.102',
  '130.41.124.222',
  '130.41.242.56',
  '130.41.242.95',
  '165.85.254.130',
  '165.85.254.187',
  '165.85.254.188',
  '165.85.254.201',
  '64.187.131.162',
  '64.187.142.56',

  // THEO DECOHA
  '82.65.124.183',

  // IKOVA
  '93.23.129.109',
  '91.214.66.13',
  '84.239.67.49',

  //MAEL
  '37.67.24.40',
  '88.183.3.144',
  //QUENTIN
  '90.39.194.192',
];

@Injectable()
export class AuthService {
  private readonly CURRENT_USER_TOKENS = 'current_user_tokens';
  private readonly CURRENT_USERNAME = 'current_username';

  private authUrl = environment.api.authUrl + '/auth';
  private readonly loginEndpoint: string;
  public readonly refreshTokenEndpoint: string;

  private http: HttpClient;

  private authTokenSubject: BehaviorSubject<AuthTokens>;

  constructor(
    private toastr: ToastrService,
    private handler: HttpBackend,
    private router: Router
  ) {
    this.loginEndpoint = this.authUrl + '/authorize';
    this.refreshTokenEndpoint = this.authUrl + '/refresh-token';
    this.http = new HttpClient(handler);
    this.authTokenSubject = new BehaviorSubject<AuthTokens>(
      this.getAuthToken()
    );
  }

  public get authTokensValue(): AuthTokens {
    return this.authTokenSubject.value;
  }

  login(credentials: Credentials): Observable<AuthTokens> {
    return this.http
      .post<AuthTokens>(this.loginEndpoint + '/admin', credentials)
      .pipe(
        tap((authTokens) => {
          this.storeAuthToken(authTokens);
          this.storeUsername(credentials.username);
        })
      );
  }

  async checkIfPileje() {
    const token = localStorage.getItem('current_user_tokens');
    const { username } = jwtDecode(token) as any;
    
    if (!PILEJE_ADMIN_USERNAMES.includes(username)) {
      this.toastr.error(
        "Vous n'êtes pas autorisé à effectuer cette action!",
        'Contactez votre administrateur'
      );
      return false;
    }

    const { ip } = await this.getPublicIpAddress().toPromise();
    if (!IP_ADDRESS_WHITELIST.includes(ip)) { 
      this.toastr.error(
        "Vous n'êtes pas autorisé à effectuer cette action depuis vore lieu actuel !",
        'Contactez votre administrateur'
      );
      return false;
    }
    return true;
  }

  checkIfNotPileje(withToastr = true) {
    const token = localStorage.getItem('current_user_tokens');
    const { username } = jwtDecode(token) as any;
    if (PILEJE_ADMIN_USERNAMES.includes(username)) {
      if (withToastr) {
        this.toastr.error(
          "Vous n'êtes pas autorisé à effectuer cette action!",
          'Contactez votre administrateur'
        );
      }
      return false;
    }
    return true;
  }

  getPublicIpAddress() {
    return this.http.get<{ ip: string }>('https://api.ipify.org?format=json');
  }

  refreshToken(): Observable<AuthTokens> {
    const token = this.getAuthToken();
    if (!token) {
      return of(null);
    }
    return this.http
      .get<AuthTokens>(this.refreshTokenEndpoint, {
        headers: {
          Authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .pipe(
        tap((authTokens) => {
          this.storeAuthToken(authTokens);
        }),
        catchError((error) => {
          this.logout();
          return throwError(error);
        })
      );
  }

  logout() {
    this.removeAuthTokens();
    this.removeUsername();
    this.router.navigate(['connexion']);
  }

  getCurrentUser(): string | null {
    const username = this.getUsername();
    return username ? username : null;
  }

  private storeUsername(username: string): void {
    localStorage.setItem(this.CURRENT_USERNAME, username);
  }

  private getUsername(): string | null {
    return localStorage.getItem(this.CURRENT_USERNAME);
  }

  private removeUsername(): void {
    localStorage.removeItem(this.CURRENT_USERNAME);
  }

  isAuthenticated(): Observable<boolean> {
    return this.refreshToken().pipe(
      map((authTokens) => {
        if (!authTokens) {
          return false;
        }
        return true;
      }),
      catchError((error) => {
        return of(false);
      })
    );
  }

  private storeAuthToken(authTokens: AuthTokens): void {
    localStorage.setItem(this.CURRENT_USER_TOKENS, JSON.stringify(authTokens));
    this.authTokenSubject.next(authTokens);
  }

  private getAuthToken(): AuthTokens {
    return JSON.parse(localStorage.getItem(this.CURRENT_USER_TOKENS));
  }

  private removeAuthTokens(): void {
    localStorage.removeItem(this.CURRENT_USER_TOKENS);
    this.authTokenSubject.next(null);
  }
}
